import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo/seo";
import React from "react";
import Hero from "../sections/flop-events/wpt/Hero";
import {Box, Button, Section, Text, Title} from "../components/Core";

import acceptedCountriesPdf from "../assets/pdf/WPT_List_of_all_accepted_countries.pdf";

import firstImage from "../assets/image/flop/event/wpt/section01_illustration 4.png";
import footerImage from "../assets/image/flop/event/wpt/section03_illustration 1.png";
import secondImage from "../assets/image/flop/event/wpt/section02_illustration 3.png";
import {Container} from "reactstrap";

const WptPage = () => {
  return (
    <PageWrapper headerDark bg={'black'}>
      <Seo
        title="First Land of Poker and WPT Global"
        desc="First Land of Poker and WPT GlobalFLOP is pleased to announce a new partnership with World Poker Tour (WPT) Global, becoming an affiliate of WPT’s new online poker site."
      />
      <Hero/>
      <Section pt={[0, 0, 0, 0, 100]} py={0} pb={80}>
        <Container>
          <Box pl={[0, 0, 0, 0, 0]}>
            <article>
              <Title className={"text-center"}
                     style={{maxWidth: 1000, textAlign: 'center', margin: '44px  auto 40px auto'}}>
                Free Tournament Ticket worth $110 for $100k launch event
              </Title>
              <section className="d-flex align-items-start flex-wrap">
                <img src={firstImage} alt="" style={{width: 360, marginTop: -30, marginRight: 40}}/>
                <div style={{flex: '1 400px'}}>
                  <div style={{maxWidth: 750}}>
                    <Text mb={20} fontSize={24} lineHeight={1.4}>
                      WPT Global is holding a $100,000 launch event tournament, with a $110 buy-in and
                      flights
                      taking
                      place every Wednesday and Sunday, the last flight on May 29th.
                    </Text>
                    <Text mb={20} fontSize={24} lineHeight={1.4}>
                      All new players registering before this date will get a free entry into this
                      tournament,
                      simply by
                      opening and verifying an account, no deposit required! Sign Up Via this Link to
                      take
                      advantage of
                      this generous welcome offer.
                    </Text>

                    <div className="d-flex flex-column flex-wrap align-items-start mb-3">
                      <a href={"https://wlwptonline.adsrv.eacdn.com/C.ashx?btag=a_18b_75c_&affid=16&siteid=18&adid=75&c="} target="_blank">
                        <Button mb={0} bg="#005EE1" px={50}>Register now</Button>
                      </a>
                    </div>
                  </div>
                </div>

              </section>

            </article>

            <Box mt={[0, 0, 0, 0, 80]}>
              <Title className={"text-center"}
                     style={{maxWidth: 1000, textAlign: 'center', margin: '44px  auto 40px auto'}}>Welcome matched
                deposit bonus of up to $1,200</Title>
              <section className="d-flex align-items-start flex-wrap-reverse">
                <div style={{flex: '1 400px'}}>
                  <div style={{maxWidth: 750}}>
                    <Text mb={20} fontSize={24} lineHeight={1.4}>Open to all new depositors, depositing between
                      $20-$1,200. Play real money cash games or tournaments to unlock the deposit, which will be
                      released in blocks of $5 (for every $20 raked).
                    </Text>

                    <Text mb={20} fontSize={24} lineHeight={1.4}>
                      Valid for 90-days after making deposit.
                    </Text>
                    <Text mb={20} fontSize={24} lineHeight={1.4}>
                      Open an account and deposit to Claim Your Bonus.
                    </Text>

                    <div className="d-flex flex-column flex-wrap align-items-start mb-3">
                      <a href={"https://wlwptonline.adsrv.eacdn.com/C.ashx?btag=a_18b_75c_&affid=16&siteid=18&adid=75&c="} target="_blank">
                        <Button mb={0} bg="#005EE1" px={50}>Get your bonus</Button>
                      </a>
                    </div>
                  </div>

                </div>
                <img src={secondImage} alt="" style={{width: 360, marginTop: -30, marginRight: 40}}/>

              </section>
            </Box>

          </Box>
        </Container>
      </Section>

      <Section pt={0} py={0} pb={0} pl={20} pr={20} bg={"#0E1011"}>
        <article className="d-flex align-items-start flex-wrap" style={{color: 'white'}}>
          <img src={footerImage} alt="" style={{width: 600, marginLeft: -65}}/>
          <section style={{marginTop: 100, flex: '1 500px'}}>
            <Title color={"white"}>FLOP on WPT</Title>
            <div style={{maxWidth: 750,}}>
              <Text color={"white"} mb={20} fontSize={24} lineHeight={1.4}>First Land of Poker Ambassador Patrik
                Antonius will be
                hosting exclusive online tournaments and cash games for FLOP users on WPT Global. More information
                coming soon!
              </Text>
              <Text color={"white"} mb={20} fontSize={24} lineHeight={1.4}>
                Check the FLOP blog for regular updates, tournament announcements, special promotions and a chance to
                play against Patrik!</Text>
              <div className="d-flex flex-column align-items-start mb-3 flex-wrap">
                <a href={'https://firstlandofpoker.com/en/blog'} target="_blank">
                  <Button mb={0} bg="#005EE1" px={50}>Read our Blog</Button>
                </a>
              </div>
            </div>
          </section>
        </article>
      </Section>
      <Section pt={[0, 0, 0, 0, 60]} py={0} pb={0}>
        <Container>
          <article>
            <Title className={"text-center"}
                   style={{maxWidth: 1000, textAlign: 'center', margin: '44px  auto 40px auto'}}>Eligible
              Countries</Title>
            <Text mb={20} fontSize={24} lineHeight={1.4}>
              The site is accessible from 85 countries, with further territories expected to be added in the near
              future.

              In Europe, WPT Online is currently available to players from <b>Andorra, Bosnia & Herzegovina, Croatia,
              Finland, Georgia, Hungary, Iceland, Ireland, Liechtenstein, Luxembourg, Moldova, Monaco, Montenegro, North
              Macedonia, Poland, Slovakia and Slovenia</b>.
              <Text mt={[40, 40, 40, 40, 70]} mb={[0, 0, 0, 0, 150]} fontSize={24} lineHeight={1.4}>
                <a href={acceptedCountriesPdf} target="_blank"><span style={{textDecoration: 'underline', color: '#005EE1'}}>The full list of accepted counties can be found here</span></a>
              </Text>
            </Text>
          </article>
        </Container>
      </Section>
    </PageWrapper>
  );
};
export default WptPage;
