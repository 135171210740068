import React from "react";
import styled from "styled-components";
import {Row, Col} from "react-bootstrap";
import {Title, Button, Section, Box, Text} from "../../../components/Core";
import headerPng from "../../../assets/image/flop/event/wpt/flop_wpt 14.png";
import {Container} from "reactstrap";

const ImgRight = styled.img`
  width: 100%;
`;

const Hero = () => {
  return (
    <Section pt={100} py={0} pb={20} bg="black" className="position-relative">
      <Container>
        <Box>
          <Row className="justify-content-center align-items-center">
            <Col lg="6" md="10" sm="9" className="order-lg-2">
              <Row className={'justify-content-end'}>
                <div className="text-center text-lg-right position-relative">
                  <div
                    className="img-main"
                    data-aos="fade-down"
                    data-aos-duration="750"
                    data-aos-once="true"
                    data-aos-delay="500"
                  >
                    <ImgRight
                      src={headerPng}
                      alt="WPT Presentation"
                    />
                  </div>
                </div>
              </Row>
            </Col>
            <Col lg="6" className="order-lg-1" style={{paddingLeft: 20, paddingRight: 20}}>
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Box pr={5} pt={[null]}>
                  <Title color="light">
                    First Land of Poker and WPT Global
                  </Title>
                  <Text color="light" mb={10}>First Land of Poker and WPT GlobalFLOP is pleased to
                    announce a new partnership with World Poker Tour (WPT) Global, becoming an
                    affiliate of WPT’s new online poker site.</Text>

                  <Text color="light" mb={4}>WPT Global offers a wide variety of cash games and
                    tournaments, including satellites to WPT Live events. The new site, a welcome
                    addition to the online poker landscape, is also offering some massive giveaways
                    to celebrate its launch.</Text>
                </Box>
              </div>
              <div className="d-flex flex-column align-items-start mb-3">
                <a href={"https://wlwptonline.adsrv.eacdn.com/C.ashx?btag=a_18b_75c_&affid=16&siteid=18&adid=75&c="} target="_blank">
                  <Button mb={0} bg="#005EE1" px={50}>Get started</Button>
                </a>
              </div>
            </Col>
          </Row>
        </Box>
      </Container>
    </Section>
  );
};

export default Hero;
